document.addEventListener("DOMContentLoaded", addFunctionality);

function addFunctionality() {
	emailButton();
	deleteButton();
}

function emailButton() {
	const emailBttnArray = document.querySelectorAll(".email-button");

	emailBttnArray.forEach(element => {
		element.addEventListener("click", sendEmail);
	});
}

function sendEmail(event) {
	event.preventDefault();
	var email = "sanchezmeco.javier@gmail.com";
	var subject = "¡Me gustaría contactar contigo, Javier!";

	document.location = "mailto:" + email + "?subject=" + subject;
}

function deleteButton() {
	const deleteForms = document.querySelectorAll(".delete");
	deleteForms.forEach(deleteForm => {
		deleteForm.addEventListener("click", createConfirmation);
	});
}

function createConfirmation(event) {
	event.preventDefault();

	const modal = document.createElement("div");
	modal.classList.add("modal");
	const text = document.createElement("h1");
	text.textContent = "¿Estás seguro?";
	const cancel = document.createElement("input");
	const confirm = document.createElement("input");
	const buttons = document.createElement("div");
	const form = document.createElement("form");

	buttons.classList.add("modal-buttons");
	cancel.classList.add("secondary-button");
	confirm.classList.add("delete-button");
	
	// add delete function to conbfirm button with id from php? crear o copiar el input hidden y ponerle ese valor
	cancel.type = "submit";
	cancel.value = "Cancelar";
	cancel.addEventListener("click", function(e) {
		e.preventDefault();
		modal.remove();
	});

	confirm.type = "submit";
	confirm.value = "Eliminar";
	form.appendChild(cancel);
	form.appendChild(confirm);
	form.method = "POST";
	form.action = "delete" + event.target.parentElement.firstElementChild.getAttribute("category") + 
		"?id=" + event.target.parentElement.firstElementChild.value;
		
	buttons.appendChild(form);
	modal.appendChild(text);
	modal.appendChild(buttons);

	document.body.appendChild(modal);
}